import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { slugToTitle } from '../../utils/helpers';
import GatsbyLink from '../GatsbyLink';
import './Breadcrumbs.scss';

export const Breadcrumbs = (props) => {
  const {
    crumbLabel,
    location,
    overwrite
  } = props;

  if (!location) {
    return null;
  }

  const { pathname, origin, href } = location;
  const pathArray = pathname.split('/');
  let urlBuild = [origin];

  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul>
          {overwrite ?
            <span>{overwrite}</span>
            :
            <>
            <li><GatsbyLink to={origin}>Home</GatsbyLink></li>
            {pathArray && pathArray.map((item, index) => {
              if (item) {
                urlBuild.push( item );
                return (
                  <li key={index}>
                    <span>/</span>{item === 'category' ? <span>{item.replace('-', ' ')}</span> : <GatsbyLink to={`${urlBuild.join('/')}/`} decode={true}>{item.replace('-', ' ')}</GatsbyLink> }
                  </li>
                )
              }
            })}
            </>
          }
        </ul>
      </div>
    </div>
  );
}
