import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink'
import './PostListNavigation.scss'
import Carousel from '../../Carousel';

export const ListNavigation = props => {
    const { data, pathPrefix } = props;
    const { allWordpressCategory } = data;
    const { nodes: categories } = allWordpressCategory


}


export const PostListNavigation = (props) => {
  const settings = {
    container: `blog-nav`,
    nav: false,
    controls:true,
    mouseDrag: true,
    loop: false,
    //items: 3,
    autoWidth: true,
    gutter: 5,
  };

  const { pathPrefix, categoryFilter } = props;

  const { nodes: posts } = categoryFilter

  let categoriesArray = []
    posts && posts.map(post =>
      post.categories && post.categories.map( category => {
        let thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id)
        !thisCompare && category.slug !== 'uncategorized' && categoriesArray.push(category)
      })
    )

  return (
    <div className="post-list-navigation">
      <div className="wrap">
        <div className="inner">
          <strong>Filter by: </strong>
          <div className="navigation-container">
            <div className="inner">
              <Carousel settings={settings}>
                <div><GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">All</GatsbyLink></div>
                {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
                  <div key={index}><GatsbyLink to={`/${pathPrefix}/category/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }} /></div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
